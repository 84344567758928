import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Header from "../components/elements/Header"
import Footer from "../components/footer"
import Hero, { HeroContent } from "../components/hero"
import SidebarAds from "../components/ads/adsense/sidebar"
import YandexRtb from "../components/ads/yandex/rtb"

import "../styles/main.sass"

const getData = graphql`
  {
    site {
      info: siteMetadata {
        menu {
          className
          action
          title
          icon
        }
        navigation {
          href
          label
        }
      }
    }
  }
`

const LayoutBlog = ({ breadcrumbs, backLink = "/", children, page }) => {
  const {
    site: {
      info: { menu, navigation },
    },
  } = useStaticQuery(getData)

  return (
    <>
      <Header menu={menu} navigation={navigation} />

      <Hero breadcrumbs={breadcrumbs} backLink={backLink}>
        {page.hero ? (<HeroContent>{page.hero}</HeroContent>) : null}
      </Hero>

      <BlogRow>
        <BlogLeftAside className="col-padding">
          {page.leftAside ? page.leftAsid : null}
        </BlogLeftAside>
        <BlogContent>{page.content ? page.content : null}</BlogContent>
        <BlogRightAside className="col-padding">
          {
            page.showAside ? (
              <AsideModules>
                <AsideModule>
                  <SidebarAds />
                </AsideModule>
                <AsideModule>
                  <YandexRtb blockId="R-A-330006-2" />
                </AsideModule>
              </AsideModules>
            ) : null
          }
        </BlogRightAside>
      </BlogRow>

      {children}

      <Footer navigation={navigation} />
    </>
  )
}

export default LayoutBlog

const AsideModules = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: var(--blog-aside-top-ads-mt);
`

const AsideModule = styled.div`
  margin-bottom: 1rem;
`

const BlogRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--blog-row-mt);
`

const BlogLeftAside = styled.div`
  --size: var(--blog-left-aside-size);
`

const BlogContent = styled.div`
  --size: var(--blog-content-size);
  width: calc(100% / var(--columns) * var(--size));
  background: var(--color-surface);
  color: var(--color-on-surface);
  border-top-left-radius: 1rem;
  margin-bottom: 2rem;
`

const BlogRightAside = styled.aside`
  --size: var(--blog-right-aside-size);
  background: var(--color-surface);
  color: var(--color-on-surface);
  margin-bottom: 2rem;
`
