import React from "react"
import styled from "styled-components"
import Icon from "../icon"

export const MetaItem = ({ icon, heading, small, className }) => {
  return (
    <MetaItemWrapper className={className || ""}>
      {icon && <Icon name={icon} />}
      <MetaItemDetails>
        {heading && <span className="heading">{heading}</span>}
        {small && <span className="small">{small}</span>}
      </MetaItemDetails>
    </MetaItemWrapper>
  )
}

const MetaList = ({ children }) => {
  return <MetaWrapper>{children}</MetaWrapper>
}

export default MetaList

const MetaWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.75rem var(--offset-one-side);
  border-bottom: 1px solid var(--color-background);
`

const MetaItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  .icon {
    flex-shrink: 0;
  }
`

const MetaItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  .heading {
    margin: 0;
    padding-top: 0px;
  }
  .small {
    opacity: 0.6;
    line-height: 1;
  }
`
