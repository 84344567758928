import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function Seo({ title, description, url, meta = [], lang="ru", defer=false }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteName
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const ogUrl = `${site?.siteMetadata?.siteUrl}${url}`;
  const canonical = `${site?.siteMetadata?.siteUrl}${url}`;
  const siteName = site?.siteMetadata?.title;
  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title || siteName;
  const ogImage = "";

  const siteMeta = [
    {
      name: `robots`,
      content: "index follow",
    },
    {
      name: `title`,
      content: metaTitle,
    },
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:locale`,
      content: `ru_RU`,
    },
    {
      property: `og:url`,
      content: ogUrl,
    },
    {
      property: `og:site_name`,
      content: siteName,
    },
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:title`,
      content: metaTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ];

  if (ogImage) siteMeta.push({ property: `og:image`, content: ogImage });

  const links = [];
  if (canonical) links.push({ rel: "canonical", href: canonical });

  useEffect(() => {
    try {
      window.yaContextCb=window.yaContextCb||[]
    } catch (e) {
      console.error(e)
    }
  }, [])

  return (
    <Helmet
      htmlAttributes={{ lang }}
      link={links}
      title={metaTitle}
      meta={siteMeta.concat(meta)}
      defer={defer}
    >
      <script src="https://yandex.ru/ads/system/context.js" async></script>
    </Helmet>
  );
}

export default Seo;
