import React from "react"
import styled from "styled-components"
import Avatar from "../avatar"

const Owner = ({ owner, small, className }) => {
  return (
    <OwnerWrapper className={className || ""}>
      {owner.image && <Avatar image={owner.image} />}
      <OwnerDetails>
        <span className="heading">{owner.name}</span>
        {small && <span className="small">{small}</span>}
      </OwnerDetails>
    </OwnerWrapper>
  )
}

export default Owner

const OwnerWrapper = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
`
const OwnerDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  .heading {
    margin: 0;
    padding-top: 0px;
  }
  .small {
    opacity: 0.6;
    line-height: 1;
  }
`
