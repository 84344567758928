import React from "react"
import Img from "gatsby-image"

const Image = ({
  image,
  type = "fluid",
  aspectRatio = 2,
  width = 36,
  height = 36,
  objectFit = "cover",
  objectPosition = "50% 50%",
}) => {
  if (!image) return null

  const { url, alt = "", file } = image
  if (!url) return null

  const defaultImage = { base64: "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAEALAAAAAABAAEAAAICTAEAOw==", aspectRatio, src: url, srcSet: url }

  switch (type) {
    case "fluid":
      return <Img fluid={file ? file.childImageSharp[type] : defaultImage } alt={alt} />
    case "fixed":
      return (
        <Img
          fixed={file ? file.childImageSharp[type] : { ...defaultImage, width, height }}
          objectFit={objectFit}
          objectPosition={objectPosition}
          alt={alt}
        />
      )
    default:
      return null
  }
}

export default Image
