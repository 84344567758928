import React, { useEffect } from "react";

const YandexRtb = ({ blockId }) => {
  useEffect(() => {
    try {
      window.yaContextCb.push(() => {
        Ya.Context.AdvManager.render({
          renderTo: `yandex_rtb_${blockId}`,
          blockId: `${blockId}`,
        });
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  return <div id={`yandex_rtb_${blockId}`}></div>;
};

export default YandexRtb;
