import React from "react"
import styled from "styled-components"

import Image from "../image"

const Avatar = ({ image }) => {
  return (
    <AvatarWrapper>
      <Image type="fixed" image={image} />
    </AvatarWrapper>
  )
}

export default Avatar

const AvatarWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--color-background);
`
