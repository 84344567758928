import React, { useEffect } from "react";

const SidebarAds = () => {
  // [homework-cool.ru] sidebar adaptive
  useEffect(() => {
    try {
      const adsbygoogle = window.adsbygoogle || []
      adsbygoogle.push({})
    } catch (e) {
      console.error(e)
    }
  }, [])

  return (
    <>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9467464905733344" crossOrigin="anonymous"></script>
      <ins className="adsbygoogle"
          style={{display:'block'}}
          data-ad-client="ca-pub-9467464905733344"
          data-ad-slot="9918435043"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
    </>
  )
}

export default SidebarAds